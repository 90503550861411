<template>
  <section class="page-container">
    <div
      class="header-title-back flex"
      @click="() => $router.back()"
    >
      <i class="el-icon-arrow-left"></i>
      <span>
        Back
      </span>
    </div>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        :gutter="40"
        justify="space-around">

        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="17"
          :xl="17"
          class="page-content-and-up"
        >
          <h2 class="title-page">
            {{isEdit?'Edit Policy':'Endorsement'}}
          </h2>

          <endorsement
            v-if="productId!==11&&productId!==15"
            ref="endorsement"
            :form="formData"
            @getFormData="getSubFormData"
          >
            <template slot="form">
              <motor-form
                v-if="productId==10"
                ref="motorForm"
                @getFormData="getSubFormData"></motor-form>
              <div  v-if="productId==11">
                <contractors-endorsement
                  ref="endorsement"
                  :form="formData"
                  @getFormData="getSubFormData"
                ></contractors-endorsement>
                <constractors-form
                  ref="motorForm"
                  @getFormData="getSubFormData"></constractors-form>
              </div>
              <travel-form
                v-if="productId==12"
                ref="motorForm"
                @getFormData="getSubFormData">
              </travel-form>
              <home-form
                v-if="productId==13"
                ref="motorForm"
                @getFormData="getSubFormData"></home-form>
              <employees-form
                v-if="productId==14"
                ref="motorForm"
                @getFormData="getSubFormData"></employees-form>
              <business-form
                v-if="productId==15"
                ref="motorForm"
                @getFormData="getSubFormData"></business-form>
              <domestic-form
                v-if="productId==16"
                ref="motorForm"
                @getFormData="getSubFormData"></domestic-form>
              <construction-form
                v-if="productId==17"
                ref="motorForm"
                @getFormData="getSubFormData"></construction-form>
              <other-form
                v-if="productId==18"
                ref="motorForm"
                @getFormData="getSubFormData"></other-form>
            </template>
          </endorsement>
          <business-endorsement
            v-if="productId==15"
            ref="endorsement"
            :form="formData"
            @getFormData="getSubFormData"
          >
            <template slot="form">
              <motor-form
                v-if="productId==10"
                ref="motorForm"
                @getFormData="getSubFormData"></motor-form>
              <div  v-if="productId==11">
                <contractors-endorsement
                  ref="endorsement"
                  :form="formData"
                  @getFormData="getSubFormData"
                ></contractors-endorsement>
                <constractors-form
                  ref="motorForm"
                  @getFormData="getSubFormData"></constractors-form>
              </div>
              <travel-form
                v-if="productId==12"
                ref="motorForm"
                @getFormData="getSubFormData">
              </travel-form>
              <home-form
                v-if="productId==13"
                ref="motorForm"
                @getFormData="getSubFormData"></home-form>
              <employees-form
                v-if="productId==14"
                ref="motorForm"
                @getFormData="getSubFormData"></employees-form>
              <business-form
                v-if="productId==15"
                ref="motorForm"
                @getFormData="getSubFormData"></business-form>
              <domestic-form
                v-if="productId==16"
                ref="motorForm"
                @getFormData="getSubFormData"></domestic-form>
              <construction-form
                v-if="productId==17"
                ref="motorForm"
                @getFormData="getSubFormData"></construction-form>
              <other-form
                v-if="productId==18"
                ref="motorForm"
                @getFormData="getSubFormData"></other-form>
            </template>
          </business-endorsement>
        </el-col>
        <el-col
          class="padding-none-sm-and-down  page-content-auto"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5">
          <div class="page-content-fixed">
            <endorsement-dialog
              :isSave="is_save"
              @handlerNext="hanlderNext"></endorsement-dialog>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import motorForm from '@/views/quotation/motor/motorForm'
import constractorsForm from '@/views/quotation/contractors/constractorsForm'
import contractorsEndorsement from '@/views/quotation/contractors/constractorstEndorsement'
import travelForm from '@/views/quotation/travel/travelForm'
import homeForm from '@/views/quotation/home/homeForm'
import employeesForm from '@/views/quotation/employees/employeesForm'
import businessForm from '@/views/quotation/business/businessForm'
import businessEndorsement from '@/views/quotation/business/businessEndorsement'
import domesticForm from '@/views/quotation/domestic/domesticForm'
import constructionForm from '@/views/quotation/construction/constructionForm'
import otherForm from '@/views/quotation/other/otherForm'
import endorsement from '@/views/components/motor/endorsement'
import endorsementDialog from '@/views/components/dialog/endorsementDialog'
import {endorsementQuotationMotor,endorsementQuotationConstractor,endorsementQuotationTravel,endorsementQuotationHome, endorsementQuotationEmployee,endorsementQuotationBusiness, endorsementQuotationDomestic,endorsementQuotationConstruction,endorsementQuotationOthers} from '@/api/endorsement'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'endorsementQuotation',
  components: {
    motorForm,
    endorsement,
    endorsementDialog,
    constractorsForm,
    contractorsEndorsement,
    travelForm,
    homeForm,
    employeesForm,
    businessForm,
    businessEndorsement,
    domesticForm,
    constructionForm,
    otherForm
  },
  mixins: [MixinRules,MixinOptions],
  data() {
    return {
      subForm: {
        motorForm:false,
        endorsement:false
      },
      formData: {
        draft:false,
      }
    }
  },
  computed: {
    ...mapState('quotation', ['quoteInformationForm']),
    isEdit(){
      let {order_no,isDraft} = this.$route.query||{}
      if(isDraft=='true')
        return true
      if(this.quoteInformationForm)
      {
        let {is_edit=''} = this.quoteInformationForm
        return is_edit?true:false
      }return  false
    },
    is_save(){
      if(this.quoteInformationForm)
      {
        let {is_save=false} = this.quoteInformationForm
        return is_save
      }return  false

    },
    productId(){
      let {product_id} = this.quoteInformationForm||{}
      return product_id
    }
  },
  created() {
    if(this.quoteInformationForm){
      this.formData = Object.assign(this.formData,this.quoteInformationForm)
    }
  },
  methods: {
    ...mapActions('quotation', ['setQuoteInformationForm']),
    ...mapActions('common',['setIsBackPath']),
    getSubFormData(form, data) {
      this.subForm[form] = true
      this.formData = Object.assign(this.formData, data)
    },
    handlerBack() {
      this.$router.back()
    },
    submitData(data){
      this.formData = Object.assign(this.formData, data)
    },
    handlerValidate() {
      // this.$refs.motorForm.handlerValidate()
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })

      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      let {handing_charge} = this.formData||{}
      if(handing_charge==0){
        this.$ConfirmBox({
          title: '',
          message: 'Is there any handling charge? If yes please input the value.',
          confirmBtn:'Yes',
          cancelBtn:'No',
          isCancel:true,
        }).then(() => {
        }).catch(()=>{
          this.saveEndorsement()
        })
      }else
        this.saveEndorsement()

    },
    saveEndorsement(){
      switch (this.productId){
        case 10:
          this.network().endorsementQuotationMotor()
          break
        case 11:
          this.network().endorsementQuotationConstractor()
          break
        case 12:
          this.network().endorsementQuotationTravel()
          break
        case 13:
          this.network().endorsementQuotationHome()
          break
        case 14:
          this.network().endorsementQuotationEmployee()
          break
        case 15:
          this.network().endorsementQuotationBusiness()
          break
        case 16:
          this.network().endorsementQuotationDomestic()
          break
        case 17:
          this.network().endorsementQuotationConstruction()
          break
        case 18:
          this.network().endorsementQuotationOthers()
          break
      }
    },
    hanlderNext() {

      this.handlerValidate()

    },
    handlerRouter(order_no){
      this.setQuoteInformationForm(this.formData)
      if(this.is_save)
      {
        this.setIsBackPath(false)
      }
      this.is_save?
        this.$router.back():this.$router.push({
          name: 'info',
          query:{
            order_no,
            isEndorsement:false
          }
        })
    },
    network() {
      return {
        endorsementQuotationMotor: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationMotor(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationConstractor: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationConstractor(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationTravel: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationTravel(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationHome: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationHome(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationEmployee: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationEmployee(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationBusiness: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationBusiness(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationDomestic: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationDomestic(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationConstruction: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationConstruction(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
        endorsementQuotationOthers: async () => {
          let params = {
            ...this.formData,
            is_edit:this.isEdit?1:0,
          }
          let { data } = await endorsementQuotationOthers(params)
          let {order_no} = data
          this.handlerRouter(order_no)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  position: relative;
  //padding: 15px;
  .header-title {
    color: #000000;
    font-weight: 700;
    width: 100%;


    span {
      font-size: 16px;
    }

    &.flex {
      padding: 20px 0;
      align-items: center;

      i {
        margin-right: 10px;
      }
    }
  }

  .main-container {
    .eff-box {
      width: calc(50% - 10px);
    }

    .document-wrapper {
      .flex {
        .form-item {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .drivers-file-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .card {
          position: relative;
          margin-bottom: 15px;
          width: calc(50% - 10px);

          &:nth-child(2n-1) {
            margin-right: 10px;
          }

          &:nth-child(2n) {
            margin-left: 10px;
          }

          .mask {
            z-index: 2;
            position: absolute;
            top: 40px;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }
  }

  .el-col {
    padding: 0;
  }
}
</style>
