<template>
  <el-form
    ref="form"
    :rules="rules"
    :model="formData"
  >
    <el-row
      class="row-bg"
      :gutter="30"
      justify="space-around">

      <v-row-col
      >
        <div class="form-item ">
          <span>Registration Mark <span class="tip-option">If you will change the Registration Mark, please input ***</span></span>
          <el-form-item
            ref="registration_mark"
            prop="registration_mark">
            <v-input
              v-model="formData.registration_mark"
            />
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item ">
          <span>Car Make</span>
          <el-form-item
            ref="car_make_id"
            prop="car_make_id">
            <v-select
              v-model="formData.car_make_id"
              :list="carMakeList"
            />
          </el-form-item>
        </div>
      </v-row-col>

      <v-row-col
      >
        <div class="form-item ">
          <span>Car Model</span>
          <el-form-item
            ref="car_model"
            prop="car_model">
            <v-input
              v-model="formData.car_model"
              is-upper
            />
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item ">
          <span>Chassis Number</span>
          <el-form-item
            ref="chassis_number"
            prop="chassis_number">
            <v-input
              v-model="formData.chassis_number"
              is-upper/>
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item ">
          <span>Body Type</span>
          <el-form-item
            ref="body_type_id"
            prop="body_type_id">
            <v-select
              v-model="formData.body_type_id"
              :list="carModelList"
            />
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item">
          <span>Year of Manufacture</span>
          <el-form-item
            ref="year_of_manufacture"
            prop="year_of_manufacture">
            <v-select
              v-model="formData.year_of_manufacture"
              :list="yearOption(1003)"
            />
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item">
          <span v-if="formData.body_type_id==43">Power</span>
          <span v-else>Vehicle Cylinder Capacity</span>
          <el-form-item
            ref="vehicle_cylinder_capacity"
            prop="vehicle_cylinder_capacity">
            <v-input
              v-model.number="formData.vehicle_cylinder_capacity"
              :append="formData.body_type_id==43?'kW':'cc'"
              :key-number="formData.body_type_id==43?false:true"
              :money="formData.body_type_id==43"
              :max="9999"
            />
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item ">
          <span>Seating Capacity</span>
          <el-form-item
            ref="searing_capacity"
            prop="searing_capacity">
            <v-input
              v-model="formData.searing_capacity"
            />
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
        v-if="carType==1002"
      >
        <div class="form-item ">
          <span>Permitted Gross Vehicle Weight</span>
          <el-form-item
            ref="permitted_gross_vehicle_weight"
            prop="permitted_gross_vehicle_weight">
            <v-input
              v-model="formData.permitted_gross_vehicle_weight"
              append="tonnes"
            />
          </el-form-item>
        </div>
      </v-row-col>

      <v-row-col
        v-if="formData.body_type_id!=43"
      >
        <div class="form-item ">
          <span>Engine Number</span>
          <el-form-item
            ref="engine_number"
            prop="engine_number">
            <v-input
              v-model="formData.engine_number"
              is-upper/>
          </el-form-item>
        </div>
      </v-row-col>
      <v-row-col
      >
        <div class="form-item ">
          <span>NCD</span>
          <el-form-item prop="ncd">
            <v-select
              v-model="formData.ncd"
              :list="ncdList"
              @change="changeNcd"
            />
          </el-form-item>
        </div>
      </v-row-col>
      <div v-if="ncd>0">
        <v-row-col
          :same-number="24"
          same
        >
          <div class="form-item ">
            <span>Is the subject Insured vehicle be the same as the one under the previous policy?</span>
            <div class="car-type-radio">
              <el-form-item prop="is_same_previous_car">
                <v-radio-group
                  v-model="formData.is_same_previous_car"
                  border
                  :list="yesNo2Options"></v-radio-group>
              </el-form-item>
            </div>
          </div>
        </v-row-col>

        <v-row-col
        >
          <div class="form-item ">
            <span>Previous Policy Number</span>
            <el-form-item prop="policy_number">
              <v-input
                v-model="formData.policy_number"
                is-upper></v-input>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item ">
            <span>Previous Insurance Company</span>
            <el-form-item prop="previous_insurance_company_id">
              <v-select
                v-model="formData.previous_insurance_company_id"
                :is-has-oid="isQueryRenew"
                other-key="oid"
                :list="globalOptions.previous_insurance_company"></v-select>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
        >
          <div class="form-item ">
            <span>Registration Number</span>
            <el-form-item prop="registration_number">
              <v-input
                v-model="formData.registration_number"
                is-upper></v-input>
            </el-form-item>
          </div>
        </v-row-col>
      </div>
      <v-row-col
        same
        :same-number="24">
        <div class="form-item">
          <span>Is there any modification in the above vehicle?</span>
          <div class="car-type-radio">
            <el-form-item
              ref="is_modification"
              prop="is_modification">
              <v-radio-group
                v-model="formData.is_modification"
                border
                :list="modificationOption"
              />
            </el-form-item>
          </div>
        </div>
      </v-row-col>
      <v-row-col
        same
        :same-number="24">
        <div class="form-item">
          <span>Is the vehicle fitted with Car Camcorder?</span>
          <div class="car-type-radio">
            <el-form-item
              ref="is_install_camcorder"
              prop="is_delivery_purpose">
              <v-radio-group
                v-model="formData.is_install_camcorder"
                border
                :list="camcorderOption"
              />
            </el-form-item>
          </div>
        </div>
      </v-row-col>




    </el-row>


  </el-form>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'vehicleDetailForm',

  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 1001
    },
    // ncd:{
    //   type:Number,
    //   default:0
    // }
  },
  data(){
    const checkMax = (rule, value, callback) => {
      const {vehicle_cylinder_capacity} = this.formData||{}
      // if(body_type_id==21&&vehicle_cylinder_capacity>650) {
      //   callback(new Error('請檢查所輸入的數值\n' +
      //     'Please check the entered value'))
      // }
      // if(body_type_id!=21&&vehicle_cylinder_capacity>1999) {
      //   callback(new Error('請檢查所輸入的數值\n' +
      //     'Please check the entered value'))
      // }
      if(vehicle_cylinder_capacity>9999||vehicle_cylinder_capacity=='') {
        callback(new Error('請檢查所輸入的數值\n' +
          'Please check the entered value'))
      }
      callback()
    }
    return{
      oldFormDataLock:-1,
      formData:{
        registration_mark: '',
        searing_capacity: '',
        car_make_id: '',
        chassis_number: '',
        car_model: '',
        body_type_id: '',//i
        engine_number: '',
        ncdShow: '',//i,
        ncd:'',
        vehicle_cylinder_capacity: '',//i
        year_of_manufacture: '',
        permitted_gross_vehicle_weight:'',
        policy_number:'',
        previous_insurance_company_id:'',
        registration_number:'',
        is_same_previous_car:'',
        is_delivery_purpose:'',
        is_modification:'',
        is_install_camcorder:''
      },
      rules: {
        vehicle_cylinder_capacity: [
          { validator: checkMax, trigger: ['blur','change'] }
        ]
      },
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm','ncd','ncdList']),

    carMakeList(){
      let carList = []
      this.globalOptions.car_make.some(item=>{
        if(item.class_id==this.carType)
          carList = item.option
      })
      return carList
    },
    carModelList(){
      let carList = []
      this.globalOptions.body_type.some(item=>{
        if(item.class_id==this.carType)
          carList = item.option
      })
      return carList
    },
    isRenew(){
      return   this.form.policy_type ==201&&!this.$route.query.isRenew == 'true'
    },
    isQueryRenew(){
      return this.$route.query.isRenew =='true'
    }
  },
  watch:{
    quoteInformationForm: {
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          let {vehicle_detail = {}} = val || {}
          Object.keys(this.formData).some(key => {
            if (key in vehicle_detail) {
              this.formData[key] = vehicle_detail[key]
            }
          })
          if (this.isQueryRenew) {
            this.formData.policy_number = val.policy_no
            this.formData.previous_insurance_company_id = val.company_id
            this.formData.registration_number = vehicle_detail.registration_mark || ''
          }
          // if(this.isRenew){
          //   this.formData.policy_number = val.policy_no
          //   this.formData.previous_insurance_company = this.getOptionName('globalOptions.company',val.company_id)
          //   this.formData.registration_number =vehicle_detail.registration_mark
          // }
        }
      }

    },

    ncd:{
      immediate:true,
      handler(val){
        this.formData.ncd = val
      }
    },
    'formData.body_type_id': {
      immediate: true,
      handler(val) {
        let {vehicle_cylinder_capacity} = this.formData || {}

        if(val==43){
          // this.formData.vehicle_detail.vehicle_cylinder_capacity = vehicle_cylinder_capacity>650?650:vehicle_cylinder_capacity
          // this.formData.vehicle_detail.vehicle_cylinder_capacity = val==21?650:999
        }else {
          const regex = /\./
          if (regex.test(vehicle_cylinder_capacity)) {
            this.formData.vehicle_cylinder_capacity = ''
          }
        }
        // if (val == 21) {
        //   this.formData.vehicle_cylinder_capacity = vehicle_cylinder_capacity > 650 ? 650 : vehicle_cylinder_capacity
        // } else {
        //   this.formData.vehicle_cylinder_capacity = vehicle_cylinder_capacity > 999 ? 999 : vehicle_cylinder_capacity
        // }
      }
    }
  },
  methods:{
    ...mapActions('quotation', ['setNcd']),

    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate((valid,rules) => {
          if(valid) {
            this.submitData(formName)
            resolve(valid)
          } else {
            this.scrollToValidDom(rules)
            reject(valid)
          }
        })
      })
    },
    submitData(formName){
      let data ={
        vehicle_detail:{...this.formData}
      }
      this.$emit('getFormData', formName, data)

    },
    changeNcd(){
      this.setNcd(this.formData.ncd)
    }
  }
}
</script>

<style scoped>

</style>
