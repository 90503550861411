<template>
  <el-form
    ref="form"
    :rules="rules"
    :model="formData"
  >
    <!--    motor-->
    <div class="insure-page">
      <el-row
        class="row-bg"
        justify="space-around">
        <el-form-item
          ref="company_id"
          prop="company_id"
          :rules="rules.select">
          <div class="form-item">
            <el-col
              v-for="(item,index) in globalOptions.company"
              :key="index"
              class="img-sm-and-down"
              :xs="10"
              :sm="12"
              :md="5"
              :lg="4"
              :xl="4"
            >
              <!--              <div>QBE</div>-->
              <div
                class="policy-insurer hand"
                :class="item.id==formData.company_id?'is-checked':''"
                @click="handlerInsurer(index,item.id)">

                <img
                  :src="getCompanyImage(item.id)"
                  alt="">
              </div>
            </el-col>
          </div>
        </el-form-item>
      </el-row>
      <!--      <el-row-->
      <!--        :gutter="20"-->
      <!--        justify="space-around">-->
      <!--        <el-col-->
      <!--          :xs="24"-->
      <!--          :sm="24"-->
      <!--          :md="12"-->
      <!--          :lg="12"-->
      <!--          :xl="12">-->
      <!--          <div-->
      <!--            class="form-item">-->
      <!--            <span  class="little-title">Other Insurers</span>-->
      <!--            <div>-->
      <!--              <el-form-item prop="company_id">-->
      <!--                <v-select-->
      <!--                  v-model.number="formData.company_id"-->
      <!--                  :list="companyList"-->
      <!--                  :disabled="isEdit"-->
      <!--                  @change="hanlderChange"></v-select>-->
      <!--              </el-form-item>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--        </el-col>-->
      <!--      </el-row>-->
    </div>
  </el-form>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'insurance',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    policyType:{
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      carSelect: '',
      coverType:0,
      formData:{
        company_id:'',//r
      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID']),
    getCompanyImage(){
      return (id)=>{
        let arr = this.insureOptions.filter(item=>item.id==id)||[]
        return arr.length>0?arr[0].logo:''
      }
    },
    companyList(){
      let  length = this.globalOptions.company.length
      return this.globalOptions.company
    },
    showOther(){
      return this.productID==18
    },
    isEndorsement()
    {
      return this.form.policy_type ==203||this.$route.query.isEndorsement=='true'
    },
    isEdit(){
      let {is_edit='',edit_all} = this.form||{}
      if(edit_all)
        return false
      return is_edit?true:false
    },
  },
  watch:{
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if(val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          let {company_id,premium} = val||{}
          let {insurer_net} = premium||{}
          this.formData.company_id = company_id||''
          let {company} = this.globalOptions||{}
          let res = company.find(item=>item.id==company_id)||{}
          if(!insurer_net)
            this.setInsureNet(res.insurer_net)
        }
      }
    },

  },
  methods:{
    ...mapActions('quotation', ['setNcdList','setNcd','setCompanyID','setInsureNet']),
    handleSelectCompany(){
      this.setCompanyID(this.formData.company_id)
    },
    handlerInsurer(index,id){
      // if(this.isEdit||this.isRenew)return
      // if(this.isEdit)return
      this.insurerIndex = index
      // if(id)
      this.formData.company_id = id||0
      let {company} = this.globalOptions||{}
      let res = company.find(item=>item.id==id)||{}
      this.setInsureNet(res.insurer_net)
      this.hanlderChange()
    },
    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate((valid,rules) => {
          if(valid) {
            this.$emit('getFormData', formName, this.formData)
            resolve(valid)
          } else {
            this.scrollToValidDom(rules)
            reject(valid)
          }
        })
      })
    },
    //提交给父类
    hanlderChange(){
      this.$emit('policyData',this.formData)
    },
  }
}
</script>

<style scoped lang="scss">
.insure-page{
  .el-col{
    margin-right: 12px;
  }
  .policy-insurer{
    display: flex;
    justify-content: center;
    align-items: center;
    //height: 64px;
    width: 100%;
    height: 80px;
    margin-bottom: 10px;
    border: 1px solid $primary;
    box-sizing: border-box;
    border-radius: 4px;
    background: #fff;
    img{
      width: 70%;
      display: block;
    }
  }
  .is-checked{
    background: $primary-bg;
    border: 2px solid $primary;
  }
  .car-type-radio{
    ::v-deep .v-radio-group{
      .el-radio-group{
        display: flex;
        flex-wrap: wrap;
        .el-radio__label{
          margin-left: 0;
          text-align: center;
        }
        .el-radio{
          margin-right: 12px;
          margin-bottom: 12px;
          color: $primary;
          border-color:$primary ;
          font-weight: bold;
          background: #fff;
        }
        .el-radio__input{
          display: none!important;
        }
        .is-bordered{
          margin-left: 0;
        }
        .is-checked{
          background: $primary-bg;
          color: #076081!important;
          border: 2px solid #076081;
          .el-radio__label{
            color: #076081!important;
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
